<template>
  <div class="biographyAnimation" :class="{loading: person != null}">
    <video v-if="person.gif && animate" class="biographyAnimation_video" autoplay loop muted playsinline>
      <source :src="person.gif" type="video/mp4">
    </video>
    <img class="image image-fit biographyAnimation_photo active"
      v-else
      :src="person.photo"
      :alt="person.name">
  </div>
</template>

<script>
import mediaApi from "../../api/media"

export default {
  name: 'BiographyAnimation',
  props: {
    person: {required: true}
  },
  data() {
    return {
      imagesData: [],
      active: 0,
      sequencer: null,
      nextIn: 5,
      animate: true,
    }
  },
  computed: {
    imageIds() {
      if( this.person ) {
        if( this.person.bio_animation_photos && this.person.bio_animation_photos != "" ) {
          return JSON.parse(`[${this.person.bio_animation_photos}]`)
        }
      }
      return []
    },
    members() {
      return this.$store.getters['projects/team']
    },
    delayInfo() {
      if( this.person ) {
        return JSON.parse(this.person.bio_animation_delay)
      }
      return []
    }
  },
  methods: {
    addImages(data) {
      this.imagesData = [...this.imagesData, ...data]
      this.start()
    },
    imageSrc(id) {
      let image = this.imagesData.find( (image) => image.id == id)
      if( image ) {
        return image.source_url
      }
      return ''
    },
    start() {
      this.end()
      this.active = 0
      this.nextIn = this.delayInfo[this.active]
      this.sequencer = setInterval(() => {
        this.nextIn--
        if( this.nextIn < 1 ) {
          this.active = (this.active + 1)%this.imageIds.length
          this.nextIn = this.delayInfo[this.active]
        }
      }, (1000/15))
    },
    end() {
      clearInterval(this.sequencer)
    },
  },
  watch: {
    person: {
      handler() {
        if( this.animate ) {
          this.imagesData = []
          if( this.person && this.person.bio_animation_photos && this.person.bio_animation_photos.length > 0) {
            mediaApi.get((data) => this.addImages(data), {include: this.person.bio_animation_photos})
          }
        }
      },
      immediate: true,
    },
  },
  mounted() {
    let mobile = parseInt(getComputedStyle(document.body).getPropertyValue('--touch-device'))
    this.animate = mobile == 0
  },
  destroyed() {
  },
}
</script>

<style lang="scss" scoped>
.biographyAnimation {
  height: 100%;
  width: 100%;
  &_video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  &_photo {
    &:not(.active) {
      display: none;
    }
  }
}
</style>
