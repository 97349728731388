<template>
  <div id="quit" @click.prevent="quitGame">
    <svg>
      <use href="../assets/icons.svg#close"></use>
    </svg>
  </div>
</template>

<script>
import { EventBus } from '../events'

export default {
  name: 'QuitButton',
  props: {
  },
  methods: {
    quitGame() {
      EventBus.$emit('gameMode', false);
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
