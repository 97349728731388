<template>
  <div class="gameHelp" @click.prevent="exit">
    <div class="gameHelp_content">
      <h2>TIP</h2>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { EventBus } from '../../events'
export default {
  name: 'GameHelp',
  props: {
  },
  data() {
    return {
    }
  },
  computed: {
  },
  watch: {
  },
  methods: {
    exit() {
      EventBus.$emit('gameMode', false);
    }
  },
  mounted() {
  },
}
</script>

<style scoped>
</style>
