<template>
  <li class="rosterMember" @mouseenter="lookAtMe()" @mouseleave="stop()">
    <router-link class="rosterMember_link caps" :to="{name: 'about-person', params: {slug: member.slug}}">
    <!-- <div class="rosterMember_link caps"> -->
      <img class="rosterMember_photo" :src="photo" alt="">
      <transition name="quickfade" mode="in-out">
        <img class="rosterMember_photo" :src="lookAt(direction)" alt="" v-if="looking" :key="direction">
      </transition>
      <div class="rosterMember_about">
        <span class="rosterMember_name" v-text="member.name"></span>
        <span class="rosterMember_jobTitle" v-text="member.job_title"></span>
      </div>
    </router-link>
    <!-- </div> -->
  </li>
</template>

<script>
import { EventBus } from '../events'

export default {
  name: 'MemberButton',
  props: {
    member: {required: true},
  },
  data() {
    return {
      looking: false,
      direction: 'straight',
      lookTimeout: null,
    }
  },
  methods: {
    look(data) {
      clearTimeout(this.lookTimeout)
      if( data != null && data != this.$el) {
        if( this.$el.offsetTop < data.offsetTop ) {
          this.direction = 'bottom'
          if( this.$el.offsetLeft < data.offsetLeft ) {
            this.direction += '_right'
          } else if( this.$el.offsetLeft > data.offsetLeft ) {
            this.direction += '_left'
          }
        } else if( this.$el.offsetTop > data.offsetTop ) {
          this.direction = 'top'
          if( this.$el.offsetLeft < data.offsetLeft ) {
            this.direction += '_right'
          } else if( this.$el.offsetLeft > data.offsetLeft ) {
            this.direction += '_left'
          }
        } else {
          if( this.$el.offsetLeft < data.offsetLeft ) {
            this.direction = 'right'
          } else {
            this.direction = 'left'
          }
        }
        this.lookTimeout = setTimeout(() => {
          this.looking = true
        }, 50)
      } else {
        this.lookTimeout = setTimeout(() => {
          this.looking = false
          this.direction = 'straight'
        }, 50)
      }
    },
    lookAtMe() {
      EventBus.$emit('MemberLook', this.$el)
    },
    stop() {
      EventBus.$emit('MemberLook', null)
    },
    lookAt(position) {
      if( this.looking ) {
        let photo = this.member.direction_photos[position] 
        if( photo ) {
          return photo.original
        }
      }
      return '#'
    }
  },
  computed: {
    photo() {
      if( this.looking ) {
        console.log( this.member.direction_photos[this.direction] )
        let photo = this.member.direction_photos[this.direction] 
        if( photo ) {
          return photo || `https://source.unsplash.com/random/600x900?${this.direction}`
        }
      }
      return this.member.photo || `https://source.unsplash.com/random/600x900?what=${Math.random()}`
    }
  },
  mounted() {
    EventBus.$on('MemberLook', this.look)
  },
  destroyed() {
    EventBus.$off('MemberLook', this.look)
  }
}
</script>