<template>
  <div id="aboutPerson" class="section aboutPerson">
    <transition name="fade">
      <router-view class="aboutPerson_biography" :key="slug"></router-view>
    </transition>
    <div class="roster aboutPerson_team">
      <h2 class="title roster_title lean title-section caps">Meet the rest of the Team</h2>
      <ul class="roster_list roster_list-line">
        <rooster-member v-for="member in members" :key="member.id" :member="member" class="rosterMember_link-flat">
        </rooster-member>
    </ul>
    </div>
  </div>
</template>

<script>
import RoosterMember from '../MemberButton'
export default {
  components: {
    RoosterMember
  },
  name: 'AboutPerson',
  props: {
    slug: {required: true},
  },
  data() {
    return {
      person: null,
    }
  },
  computed: {
    members() {
      let team = [...this.$store.getters['people/all']]
      for (var i = team.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = team[i];
        team[i] = team[j];
        team[j] = temp;
      }
      return team;
    },
    backgroundStyle() {
      if( this.page ) {
        return {
          'background-image': `url(${this.page.featured_image.original})`,
        }
      }
      return {};
    }
  },
  watch: {
    $route() {
      this.$scroll(0)
    }
  },
  mounted() {
    this.$store.dispatch('people/get')
  }
}
</script>