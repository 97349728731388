import projects from '../../api/projects'

// initial state
const state = {
  projects: [],
  team: [],
  categories: [],
  tags: [],
  fetching: [],
}

// getters
const getters = {
  all: (state) => {
    return state.projects;
  },
  clients: (state, getters) => (tags = null) => {
    let theTags = getters.tagsSlugs(['selected', 'main'])
    let selectedTag = theTags.find( ({slug}) => slug == 'selected')
    let mainTag = theTags.find( ({slug}) => slug == 'main')
    console.log("DA TAGS")
    console.log(selectedTag)
    console.log(mainTag)
    console.log("AD TAGS")
    let category = state.categories.find( (category) => category.slug == 'client' );
    if( category ) {
      let clients = state.projects.filter( project => {
        let includesCategory = project.project_category.includes(category.term_id);
        if( includesCategory && tags != null ) {
          return tags.find( (tag) => project.project_tag.includes(tag.term_id) ) != null;
        }
        return includesCategory;
      });
      return clients.sort( (a,b) => {
        if( selectedTag && mainTag ) {
          if( a.project_tag.includes(selectedTag.term_id)
            && !b.project_tag.includes(selectedTag.term_id) ) {
              return -1
          } else if ( !a.project_tag.includes(selectedTag.term_id)
            && b.project_tag.includes(selectedTag.term_id) ) {
              return 1
          } else if ( a.project_tag.includes(selectedTag.term_id)
            && b.project_tag.includes(selectedTag.term_id) ) {
              // do Nothing
          } else if ( a.project_tag.includes(mainTag.term_id)
            && !b.project_tag.includes(mainTag.term_id) ) {
              return -1
          } else if ( !a.project_tag.includes(mainTag.term_id)
            && b.project_tag.includes(mainTag.term_id) ) {
              return 1
          }
        }
        return a.term_id - b.term_id
      });
    }
    return [];
  },
  work: (state) => {
    let category = state.categories.find( (category) => category.slug == 'work' );
    if( category ) {
      return state.projects.filter( project => project.project_category.includes(category.term_id));
    }
    return [];
  },
  category: (state) => (cat, ignoreTag) => {
    let projects = [];
    for (let i = 0; i < state.projects.length; i++) {
      const project = state.projects[i];
      if( project.project_category.includes(cat.cat_ID) && !project.project_tag.includes(ignoreTag.cat_ID) ) {
        projects.push(project);
      }
    }
    return projects;
  },
  categogy_tag: (state) => (cat, tag) => {
    let projects = [];
    for (let i = 0; i < state.projects.length; i++) {
      const project = state.projects[i];
      if( project.project_category.includes(cat.cat_ID) && project.project_tag.includes(tag.cat_ID) ) {
        projects.push(project);
      }
    }
    return projects;
  },
  category_slug: (state) => (category, tag) => {
    let projects = [];
    for (let i = 0; i < state.projects.length; i++) {
      const project = state.projects[i];
      if( project.project_category.includes(category.cat_ID) && project.project_tag.includes(tag.cat_ID) ) {
        projects.push(project);
      }
    }
    return projects;
  },
  without_tag: (state) => (tag) => {
    let projects = [];
    for (let i = 0; i < state.projects.length; i++) {
      const project = state.projects[i];
      if( !project.project_tag.includes(tag.cat_ID) ) {
        projects.push(project);
      }
    }
    return projects;
  },
  tag: (state) => (tag) => {
    let projects = [];
    for (let i = 0; i < state.projects.length; i++) {
      const project = state.projects[i];
      if( project.project_tag.includes(tag.cat_ID) ) {
        projects.push(project);
      }
    }
    return projects;
  },
  tagsSlugs: (state) => (slugs) => {
    return state.tags.filter((tag) => slugs.includes(tag.slug) );
  },
  team: (state) => {
    return state.team
  },
  slug: (state) => (slug) => {
    return state.projects.find( (project) => project.slug === slug );
  },
}

// actions
const actions = {
  getAll({ commit }) {
    projects.get( receivedProjects => {
      commit('add', receivedProjects);
    })
  },
  getCategories({commit}) {
    projects.getCategory((categories) => {
      commit('addCategories', categories);
    });
  },
  getClients({state, commit, dispatch}) {
    let category = state.categories.find( (category) => category.slug == 'client' );
    if(category) {
      projects.get((projs) => {
        commit('add', projs)
      }, {project_category: category.cat_ID, order: 'asc'})
    } else {
      projects.getCategory((categories) => {
        commit('addCategories', categories);
        dispatch('getClients');
      });
    }
  },
  getTags({commit}) {
    projects.getTag((tags) => {
      commit('addTags', tags);
    });
  },
  getTeam({state, commit, dispatch}) {
    if( !state.fetching.includes('team')  && state.team.length == 0) {
      commit('fetching', 'team')
      let category = state.categories.find( (category) => category.slug == 'team' )
      if( category ) {
        projects.get((projs) => {
          commit('addTeamMembers', projs)
        }, {project_category: category.cat_ID, order: 'asc'})
        commit('fetched', 'team')
      } else {
        commit('fetched', 'team')
        projects.getCategory(( categories ) => {
          commit('addCategories', categories)
          dispatch('getTeam')
        })
      }
    }
  },
  getSlug({commit}, slug) {
    projects.get( receivedProjects => {
      commit('add', receivedProjects);
    }, {slug: slug})
  },
  getWork({state, commit, dispatch}) {
    let category = state.categories.find( (category) => category.slug == 'work' );
    if(category) {
      projects.get((projs) => {
        commit('add', projs)
      }, {project_category: category.cat_ID})
    } else {
      projects.getCategory((categories) => {
        commit('addCategories', categories);
        dispatch('getWork');
      });
    }
  },
}

// mutations
const mutations = {
  add(state, projects) {
    let all = [...state.projects];
    projects.forEach( (project) => {
      let index = all.findIndex( ({id}) => id == project.id);
      if( index > -1 ) {
        all.splice( index, 1 );
      }
      all.push( project );
    })
    state.projects = all;
  },
  addCategories(state, categories) {
    let newCategories = [...state.categories];
    categories.forEach( (category) => {
      let index = categories.findIndex( ({term_id}) => term_id == category.term_id);
      if( index > -1 ) {
        newCategories.splice( index, 1 );
      }
      newCategories.push( category );
    })
    state.categories = newCategories;
  },
  addTags(state, tags) {
    let newTags = [...state.tags];
    tags.forEach( (tag) => {
      let index = newTags.findIndex( ({term_id}) => term_id == tag.term_id);
      if( index > -1 ) {
        newTags.splice( index, 1 );
      }
      newTags.push( tag );
    })
    state.tags = newTags;
  },
  fetching(state, slug) {
    if( !state.fetching.includes(slug) ) {
      state.fetching.push(slug)
    }
  },
  fetched(state, slug) {
    let index = state.fetching.findIndex( (el) => el == slug);
    let fetching = [...state.fetching]
    if( index > -1 ) {
      fetching.splice( index, 1 );
    }
    state.fetching = fetching
  },
  setProjects(state, projects) {
    state.all = projects
  },
  addTeamMembers(state, projects) {
    let all = [...state.team];
    projects.forEach( (project) => {
      let index = all.findIndex( ({id}) => id == project.id);
      if( index > -1 ) {
        all.splice( index, 1 );
      }
      all.push( project );
    })
    state.team = all;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}