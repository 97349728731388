import VueRouter from 'vue-router'
import Home from './components/templates/Home.vue'
import About from './components/templates/About.vue'
import AboutPerson from './components/templates/AboutPerson.vue'
import Biography from './components/templates/Biography.vue'
import Clients from './components/templates/Clients.vue'
import Contact from './components/templates/Contact.vue'
import Post from './components/templates/Post.vue'
import PostContent from './components/templates/Post.vue'
import Project from './components/templates/Project.vue'
import Work from './components/templates/Work.vue'
import Labs from './components/templates/Labs.vue'
import NotFound from './components/templates/404.vue';

const routes = [
  { path: '/', component: Home, name: 'home' },
  { path: '/about', component: About, name: 'about' },
  { path: '/about/:slug', component: AboutPerson, props: true, children: [
    { path: '', component: Biography,  name: 'about-person', props: true },
  ] },
  { path: '/clients', component: Clients, name: 'clients' },
  { path: '/contact', component: Contact, name: 'contact' },
  { path: '/project/:slug', component: Project, name: 'project', props: true },
  { path: '/work', component: Work, name: 'work' },
  { path: '/labs', component: Labs, name: 'labs' },
  { path: '/labs/:slug', component: Post, props: true, children: [
    { path: '', component: PostContent,  name: 'post', props: true },
  ] },
  { path: '*', component: NotFound },
]

const router = new VueRouter({
  mode: 'history',
  routes // short for `routes: routes`
})

router.beforeEach((to, from, next) => {
  next();
})

export default router 