<template>
  <transition name="fade">
    <div id="post" class="section post" :style="variables" :key="slug">
      <!-- <div class="project_banner" v-if="project"></div> -->
      <h1 class="post_title" v-text="post.title.rendered" v-if="post"></h1>
      <div class="post_content content" v-html="post.content.rendered" v-if="post"></div>
      <div class="post_others">
        <h3>Ver mais</h3>
        <div class="feed feed-3">
          <LabButton v-for="otherPost in otherPosts" :post="otherPost" :key="otherPost.id"/>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import LabButton from '../LabButton'

export default {
  components: {
    LabButton
  },
  name: 'Post',
  metaInfo() {
    return {
      title: this.title,
    }
  },
  props: {
    slug: String,
  },
  data() {
    return {}
  },
  computed: {
    post() {
      return this.$store.getters['posts/slug'](this.slug)
    },
    otherPosts() {
      let numberOfPosts = 5
      let posts = this.$store.getters['posts/all']
      if( this.post ) {
        return posts.filter((post) => {
          if( numberOfPosts > 0 && post.id != this.post.id ) {
            numberOfPosts -= 1
            return true
          }
          return false
        })
      }
      return []
    },
    title() {
      if( this.post ) {
        return this.post.title.rendered
      }
      return null
    },
    variables() {
      let style = {};
      if( this.project ) {
        style = {
          '--project-banner': `url(${this.project.banner})`,
          '--main-background-color': this.project.main_background_color,
          '--main-text-color': this.project.main_text_color,
          '--secondary-text-color': this.project.secondary_text_color,
          '--secondary-background-image': `url(${this.project.secondary_background_image})`,
        }
      }
      return style;
    },
    backgroundImage() {
      let style = {};
      if( this.project ) {
        style = {
          'background-image': `url(${this.project.banner})`,
        }
      }
      return style;
    },
  },
  watch: {
    $route() {
      this.$scroll(0, {maxDuration: 100})
    }
  },
  mounted() {
    this.$store.dispatch('posts/getAll');
  }
}
</script>

<style scoped>
</style>