<template>
  <footer class="footer">
      <div class="footer_content">
        <div class="identity">
            <p class="slogan">Outsourcing Creativity</p>
            <p class="copyright">&copy; 2020 by BLAT. All rights reserved</p>
        </div>
        <div class="links">
            <h4>Links</h4>
            <ul class="links">
                <li><a href="https://dev.blatstudio.com">dev.Blat</a></li>
                <!-- <li><a href="https://dev.blatstudio.com">Imprint</a></li> -->
                <li><a href="#">Terms & Conditions</a></li>
                <li><a href="#">Privacy Policy</a></li>
            </ul>
        </div>
        <div class="social">
            <h4>Follow us</h4>
            <ul class="links">
                <li><a href="https://www.facebook.com/blatstudio">Facebook</a></li>
                <li><a href="https://www.instagram.com/blatstudio">Instagram</a></li>
                <li><a href="https://www.linkedin.com/company/blatstudio/">LinkedIn</a></li>
                <!-- <li><a href="https://github.com/BlatStudio">GitHub</a></li>
                <li><a href="https://www.tiktok.com/@blatstudio">TikTok</a></li> -->
            </ul>
        </div>
      </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data() {
     return {
         items: [
             {title: 'Clients', path: '/' },
             {title: 'Work', path: '/work'},
             {title: 'Labs', path: '/labs'},
             {title: 'About', path: '/'},
             {title: 'Contact', path: '/'},
         ]
     } 
  }
}
</script>

<style>
</style>
