<template>
  <div id="about" class="section">
    <template v-if="page">
      <h2 class="title title-big caps" v-html="page.subtitle"></h2>
      <div class="text" v-html="page.content.rendered"></div>
      <div class="menu" :style="backgroundStyle">
        <!-- <ul>
          <li>
            <router-link to="/">Discover the Office</router-link>
          </li>
          <li>
            <router-link to="/">Meet the Team</router-link>
          </li>
        </ul> -->
      </div>

      <div class="commandments">
        <h2 class="title roster_title title-section lean caps">Our Commandments</h2>
        <ul>
          <li v-for="commandment in commandments" :key="commandment.n">
            <div class="commandment">
              <span class="commandment_number" v-text="commandment.n"></span>
              <span class="commandment_commandment" v-text="commandment.c"></span>
              <span class="commandment_line" v-text="commandment.l"></span>
            </div>
          </li>
        </ul>
      </div>

      <div class="roster">
        <h2 class="title roster_title title-section lean caps">Our Team</h2>
        <ul class="roster_list roster_list-grid">
          <rooster-member v-for="member in members" :key="member.id" :member="member">
          </rooster-member>
        </ul>
      </div>

    </template>
  </div>
</template>

<script>
import RoosterMember from '../MemberButton'

export default {
  name: 'About',
  components: {
    RoosterMember,
  },
  metaInfo() {
    if(this.$route.name == 'about') {
      return {
        title: "About"
      }
    }
    return {}
  },
  props: {
  },
  data() {
    return {
      page: null,
      commandments: [
        {n: '01', c: 'Clients are Friends', l: 'We are fully engaged with our brands.'},
        {n: '02', c: 'Results Matter', l: 'Yes, we can walk the talk.'},
        {n: '03', c: 'No sales team, just happy friends', l: 'Check /01 for a better understanding.'},
        {n: '04', c: 'Always green, but never for the job', l: "Dont't let our youth mislead you."},
        {n: '05', c: 'One stop shop', l: "Throw us what you got, we'll deliver."},
        {n: '06', c: 'Zero', l: "Amount of projects we've failed."},
        {n: '07', c: 'Consumer focused digital strategy', l: "We're not here just for the likes."},
        {n: '08', c: 'Done.', l: "If we say we will do it. We will do it."},
      ],
    }
  },
  computed: {
    backgroundStyle() {
      if( this.page ) {
        return {
          'background-image': `url(${this.page.featured_image.original})`,
        }
      }
      return {};
    },
    members() {
      let team = [...this.$store.getters['people/all']]
      for (var i = team.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = team[i];
        team[i] = team[j];
        team[j] = temp;
      }
      return team;
    },
  },
  mounted() {
    this.$api.get('wp/v2/pages', {slug: 'about'}).then( ({data}) => {
      this.page = data[0];
    });
    this.$store.dispatch('people/get')
  }
}
</script>