<template>
  <router-link :to="{name: 'project', params: {slug: work.slug} }"
    class="work workButton buttonGrad"
    :style="style">
    <h3 v-text="work.title.rendered" class="workButton_title"></h3>
    <img :src="work.featured_image.original" alt="" class="workButton_image">
    <img :src="icon" alt="" class="workButton_icon">
    <span class="workButton_explore">Explore</span>
    <p ref="excerpt" class="workButton_excerpt" v-html="excerpt(work)"></p>
  </router-link>
</template>

<script>
import icon from '../assets/plus.png';

export default {
  name: 'WorkButton',
  props: {
    work: {required: true},
  },
  data() {
    return {
      icon,
      style: {},
    }
  },
  methods: {
    setStyle() {
      let style = {
        '--excerpt-height': `${this.$refs.excerpt.clientHeight}px`,
      }
      this.style = style;
    },
    excerpt(work) {
      let maxLength = 240;
      let text = work.excerpt.rendered;
      text = text.replace(/(<([^>]+)>)/ig,""); // remove html tags
      text = text.replace(/&nbsp;/g, ' '); // remove &nbsp;
      if( text.length > maxLength ) {
        text = `${text.substring(0, maxLength)}...`;
      }
      return text;
    }
  },
  computed: {
  },
  mounted() {
    this.setStyle()
  }
}
</script>