<template>
  <div id="contact" class="section">
    <h1 class="title title-section caps lean" v-text="title" v-if="$route.name == 'contact'"></h1>
    <h2 class="title title-section caps lean" v-text="title" v-else></h2>
    <form class="contactForm" :style="backgroundStyle">
      <div class="contactForm_info">
        <h2 class="contactForm_infoTitle">Blat<sup>HQ</sup></h2>
        <p>
          Av. Infante Dom Henrique<br>
          N. 332, 1º Esquerdo,<br>
          1800-224 Lisboa<br>
          Portugal<br>
        </p>
        <p><a href="mailto:contact@blatstudio.com">contact@blatstudio.com</a></p>
        <p><a href="tel:00351211608843">21 160 88 43</a><br><small>*Chamada para rede fixa nacional</small></p>
      </div>
      <div class="contactForm_other">
        <a href="https://goo.gl/maps/RorjbVjV5wJjauyeA">Ver Localização</a>
      </div>
    </form>
  </div>
</template>

<script>

export default {
  components: {
  },
  name: 'Contact',
  metaInfo() {
    if(this.$route.name == 'contact') {
      return {
        title: "Contact"
      }
    }
    return {}
  },
  props: {
    title: {default: 'Get in Touch'}
  },
  data() {
    return {
      page: null,
    }
  },
  methods: {
  },
  computed: {
    backgroundStyle() {
      if( this.page ) {
        return {
          'background-image': `url(${this.page.featured_image.original})`,
        }
      }
      return {};
    }
  },
  mounted() {
    this.$api.get('wp/v2/pages', {slug: 'about'}).then( ({data}) => {
      this.page = data[0];
    });
  }
}
</script>