<template>
  <div id="project" class="section project" :style="variables">
    <div class="project_banner" v-if="project"></div>
    <h1 class="project_title" v-text="project.title.rendered" v-if="project"></h1>
    <div class="project_content content" v-html="project.content.rendered" v-if="project"></div>
    <div class="project_nav">
      <router-link :to="{name: 'project', params: {slug: p.slug}}"
        :style="{'background-image': `url(${p.banner})`}"
        class="buttonGrad"
        v-for="p in otherProjects" :key="p.id">
        <span v-text="p.title.rendered"></span>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Project',
  metaInfo() {
    return {
      title: this.title,
    }
  },
  props: {
    slug: String,
  },
  data() {
    return {
    }
  },
  computed: {
    otherProjects() {
      let projects = this.$store.getters['projects/work'];
      if( projects.length > 1 ) {
        let projIndex = projects.findIndex( ({id}) => id == this.project.id );
        let nextIndex = (projIndex + 1) % projects.length;
        let prevIndex = (projIndex - 1 + projects.length) % projects.length;
        return [
          projects[prevIndex],
          projects[nextIndex],
        ]
      }
      return [];
    },
    project() {
      return this.$store.getters['projects/slug'](this.slug);
    },
    title() {
      if ( this.project ) {
        return this.project.title.rendered
      }
      return ""
    },
    variables() {
      let style = {};
      if( this.project ) {
        style = {
          '--project-banner': `url(${this.project.banner})`,
          '--main-background-color': this.project.main_background_color,
          '--main-text-color': this.project.main_text_color,
          '--secondary-text-color': this.project.secondary_text_color,
          '--secondary-background-image': `url(${this.project.secondary_background_image})`,
        }
      }
      return style;
    },
    backgroundImage() {
      let style = {};
      if( this.project ) {
        style = {
          'background-image': `url(${this.project.banner})`,
        }
      }
      return style;
    },
  },
  mounted() {
    if( this.project == null ) {
      this.$store.dispatch('projects/getWork', this.slug);
      this.$store.dispatch('projects/getSlug', this.slug);
    }
  }
}
</script>

<style scoped>
</style>