<template>
    <div id="app" :class="{light: !darkMode, dark: darkMode, gamemode: gameMode}">
      <transition name="fade">
        <div class="blatmuma" v-if="konami">
          <h1 class="hover-bg hover">/BLATMUMA</h1>
        </div>
      </transition>
      <transition name="fade" appear>
        <div id="topmenu" v-if="gameMode == false">
          <BlatMenu/>
        </div>
      </transition>
      <transition name="up">
        <div class="app-content" :key="$route.name">
          <router-view class="view"></router-view>
        </div>
      </transition>
      <LightBulb @toggleDarkMode="toggleDarkMode"></LightBulb>
      <BlatFooter/>
    </div>
</template>

<script>
import BlatMenu from './components/Menu.vue'
import LightBulb from './components/LightBulb.vue'
import BlatFooter from './components/Footer.vue'
import { EventBus } from './events'
import Cookies from 'js-cookie'

export default {
  name: 'app',
  metaInfo: {
    title: 'BLAT',
    titleTemplate: '%s | BLAT',
  },
  components: {
    BlatMenu,
    LightBulb,
    BlatFooter,
  },
  data() {
    return {
      darkMode: false,
      gameMode: false,
      konami: false,
      konamiPattern: ['ArrowUp', 'ArrowUp', 'ArrowDown', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowLeft', 'ArrowRight', 'b', 'a'],
      konamiIndex: 0,
    }
  },
  methods: {
    setCookie() {
      if( this.darkMode ) {
        Cookies.set('dark', 1, { expires: 365 })
      } else {
        Cookies.set('dark', 0, { expires: 365 })
      }
    },
    toggleDarkMode() {
      this.darkMode = !this.darkMode;
      this.setCookie();
    },
    toggleGameMode(gameMode) {
      this.gameMode = gameMode;
    },
    konamiTrigger(e) {
      console.log(e.key)
      if( e.key == this.konamiPattern[this.konamiIndex] ) {
        this.konamiIndex += 1
      } else {
        this.konamiIndex = 0
      }
      this.konami = this.konamiIndex == this.konamiPattern.length
      if( this.konami ) {
        this.$gtag.event('konami', {
          'event_category': 'easter_egg_triggered',
        })
      }
    },
  },
  mounted() {
    document.addEventListener('keydown', this.konamiTrigger, {passive: true})
    EventBus.$on('toggleDarkMode', this.toggleDarkMode);
    EventBus.$on('playing', this.toggleGameMode);
    let dark = parseInt(Cookies.get('dark'));
    if( dark ) {
      this.darkMode = true;
    }
  },
  destroyed() {
    document.removeEventListener('keydown', this.konamiTrigger, {passive: true})
  },
  watch: {
  }
}
</script>