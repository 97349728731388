<template>
  <div id="labs" class="section">
    <h2 class="title title-section caps lean">Blat . Labs</h2>
    <div class="feed">
      <LabButton v-for="work in posts.slice(0, limit)" :key="work.id" :ref="`btn-${work.id}`" :post="work">
      </LabButton>
    </div>
  </div>
</template>

<script>
import LabButton from '../LabButton';
import seo from '../../assets/seo-preview.jpg';
import icon from '../../assets/plus.png';

export default {
  name: 'Labs',
  metaInfo() {
    if(this.$route.name == 'labs') {
      return {
        title: "Labs"
      }
    }
    return {}
  },
  components: {
    LabButton,
  },
  props: {
    limit: {default: 6},
  },
  computed: {
    posts() {
      return this.$store.getters['posts/all'];
    }
  },
  data() {
    return {
      icon,
      seo,
    }
  },
  methods: {
    excerpt(work) {
      let el = document.createElement('div');
      el.innerHTML = work.excerpt.rendered;
      let maxLength = 240;
      let text = "";
      el.children.forEach(element => {
        let innerText = element.innerHTML.replace('<br>', ' /');
        if( text.length > 0 ) {
          text += ' / ';
        }
        text += innerText;
      });
      if( text.length > maxLength ) {
        text = `${text.substring(0, maxLength)}...`;
      }
      return text;
    },
    resetButtons() {
      Object.keys(this.$refs).forEach( (ref) => {
        if( ref.startsWith('btn-') ) {
          let btn = this.$refs[ref][0];
          btn.setStyle();
        }
      })
    },
  },
  mounted() {
    if( this.posts.length == 0 ) {
      this.$store.dispatch('posts/getAll');
    }
  }
}
</script>