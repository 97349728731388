<template>
  <div class="page_404">
    <h1>404</h1>
    <p>A página que procuras não existe</p>
    <p><router-link to="/">Voltar à homepage</router-link></p>
  </div>
</template>

<script>
export default {
  name: 'About',
  props: {
  },
  data() {
    return {
      page: null,
      commandments: [
        {n: '01', c: 'Clients are Friends', l: 'We are fully engaged with our brands.'},
        {n: '02', c: 'Results Matter', l: 'Yes, we can walk the talk.'},
        {n: '03', c: 'No sales team, just happy friends', l: 'Check /01 for a better understanding.'},
        {n: '04', c: 'Always green, but never for the job', l: "Dont't let our youth mislead you."},
        {n: '05', c: 'One stop shop', l: "Throw us what you got, we'll deliver."},
        {n: '06', c: 'Zero', l: "Amount of clients we lost until today."},
        {n: '07', c: 'Comsumer focused digital strategy', l: "We're not here just for the likes."},
        {n: '08', c: 'Done.', l: "If we say we will do it. We will do it."},
      ],
      members: [],
    }
  },
  computed: {
    backgroundStyle() {
      if( this.page ) {
        return {
          'background-image': `url(${this.page.featured_image.original})`,
        }
      }
      return {};
    }
  },
  mounted() {
    this.$api.get('wp/v2/pages', {slug: 'about'}).then( ({data}) => {
      this.page = data[0];
    });
    this.$api.get('blat/v1/project_categories').then( ({data}) => {
      let category = data.find( category => category.slug == 'team' );
      this.$api.get('wp/v2/project', {project_category: category.cat_ID, order: 'asc'}).then( ({data}) => {
        this.members = data;
      });
    });
  }
}
</script>