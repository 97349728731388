<template>
  <div v-if="person" class="biography">
    <biography-animation :person="person" class="biography_photo"></biography-animation>
    <h1 class="title title-small caps biography_name"><span v-html="person.name"></span><sup v-html="person.nickname" v-if="person.nickname"></sup></h1>
    <h2 class="title title-smallSubtitle biography_jobTitle caps" v-html="person.job_title"></h2>
    <div class="text biography_bio" v-html="person.content"></div>
  </div>
</template>

<script>
import BiographyAnimation from "./BiographyAnimation"
export default {
  components: {
    BiographyAnimation,
  },
  metaInfo() {
    return {
      title: this.name,
    }
  },
  name: 'Biography',
  props: {
    slug: {required: true},
  },
  data() {
    return {
    }
  },
  computed: {
    backgroundStyle() {
      if( this.page ) {
        return {
          'background-image': `url(${this.page.featured_image.original})`,
        }
      }
      return {};
    },
    members() {
      return this.$store.getters['people/all']
    },
    name() {
      if( this.person ) {
        return this.person.name
      }
      return ''
    },
    person() {
      return this.members.find( ({slug}) => slug == this.slug)
    }
  },
  watch: {
  },
  mounted() {
  }
}
</script>
