<template>
  <div class="game intro">
    <h4 class="hover-bg"
      :class="{'force-stop': animating && !highlight, hover: highlight}"
      @mouseover.prevent
      @mouseenter="hover"
      @mouseleave="hoverOut">
      <span v-text="message"></span>
      <transition name="game-init">
        <ul v-if="questionActive">
          <li class="hover-bg" :key="1" @click.prevent="start()">Yes</li>
          <li class="hover-bg" :key="2" @click.prevent="finished(true)">No</li>
        </ul>
      </transition>
    </h4>
    <svg v-if="highlight && forwards" @click.prevent="$emit('scrollDown')">
      <use href="../../assets/icons.svg#down-chevron"></use>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'GameIntro',
  props: {
  },
  data() {
    return {
      msg: 'Want to Know Us?',
      end: "Lets play a game?",
      index: 0,
      animating: true,
      forwards: false,
      typingSpeed: 15,
      hoverOutTimeout: null,
      highlight: false,
    }
  },
  computed: {
    message() {
      if( this.index > 0 ) {
        return this.msg.substring(0, this.index);
      } else if( this.index < 0 ){
       return this.end.substring(0, -this.index);
      }
      return '';
    },
    questionActive() {
      return !(this.animating || this.forwards);
    },
    endBackwards() {
        return -this.index == this.end.length;
    }
  },
  watch: {
    forwards() {
      this.animating = true;
      this.animate();
    },
  },
  methods: {
    animate() {
      if( this.animating ) {
        if( this.forwards ) {
          this.index += 1;
        } else {
          this.index -= 1;
        }
        let endForward = this.forwards && (this.index == this.msg.length);
        let endBackwards = (!this.forwards) && this.endBackwards;
        if( endForward || endBackwards ) {
          this.animating = false;
        } else {
          setTimeout(() => this.animate(), this.typingSpeed);
        }
      }
    },
    start() {
      this.highlight = true;
      this.$emit('start');
      this.$gtag.event('start_game', {
        'event_category': 'game',
      })
    },
    hover() {
      if( !this.highlight ) {
        if( this.endBackwards ) {
          this.animating = false;
        }
        this.forwards = false;
        clearTimeout(this.hoverOutTimeout);
      }
    },
    hoverOut(event, force = false) {
      if( (!this.highlight) || force ) {
        this.animating = true;
        let timeout = (this.msg.length + this.end.length) * this.typingSpeed;
        if( this.endBackwards ) {
          timeout += 400;
        }
        this.hoverOutTimeout = setTimeout(() => this.forwards = true, timeout);
      }
    },
    finished() {
      this.msg = "/Outsourcing Creativity";
      this.highlight = true;
      this.animating = true;
      this.hoverOut(null, true);
      this.$emit('skip');
    }
  },
  mounted() {
    setTimeout(() => this.forwards = true, 1000);
  },
}
</script>

<style scoped>
</style>
