<template>
  <div id="helpButton" @click.prevent="toggleDarkMode">
    <svg>
      <use href="../assets/icons.svg#question"></use>
    </svg>
  </div>
</template>

<script>
import { EventBus } from '../events'

export default {
  name: 'LightBulb',
  props: {
  },
  methods: {
    toggleDarkMode() {
      EventBus.$emit('getHelp');
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
