import people from '../../api/people'

// initial state
const state = {
  people: [],
  categories: [],
  tags: [],
}

// getters
const getters = {
  all: (state) => {
    return state.people;
  },
  slug: (state) => (slug) => {
    let index = state.people.findIndex( (person) => person.slug == slug )
    if( index >= 0 ) {
      return state.people[index]
    }
    return null
  }
}

// actions
const actions = {
  get({ commit }) {
    people.get( receivedPosts => {
      commit('add', receivedPosts);
    })
  },
}

// mutations
const mutations = {
  add(state, people) {
    let all = [...state.people];
    people.forEach( (person) => {
      let index = all.findIndex( ({id}) => id == person.id);
      if( index > -1 ) {
        all.splice( index, 1 );
      }
      all.push( person );
    })
    state.people = all;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
