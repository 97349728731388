<template>
  <router-link :to="{name: 'post', params: {slug: post.slug}}" class="postButton" :style="style">
    <span v-text="post.title.rendered" class="postButton_title"></span>
    <div class="postButton_excerpt" ref="excerpt">
      <p v-html="excerpt"></p>
    </div>
    <img :src="post.featured_image.original || seo" alt="" class="postButton_image">
  </router-link>
</template>

<script>
export default {
  name: 'Labs',
  props: {
    post: {required: true},
  },
  data() {
    return {
      style: {},
    }
  },
  methods: {
    setStyle() {
      let style = {
        '--excerpt-height': `${this.$refs.excerpt.clientHeight}px`,
      }
      this.style = style;
    },
  },
  computed: {
    excerpt() {
      let maxLength = 240;
      let text = this.post.excerpt.rendered;
      text = text.replace(/(<([^>]+)>)/ig,""); // remove html tags
      text = text.replace(/&nbsp;/g, ' '); // remove &nbsp;
      if( text.length > maxLength ) {
        text = `${text.substring(0, maxLength)}...`;
      }
      return text;
    }
  },
  mounted() {
    setTimeout(() => {
      this.setStyle();
    }, 100)
  }
}
</script>