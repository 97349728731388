<template>
  <div class="game darklight" :class="{win: won}">
    <h4>
      <span>
        We bring
        <span ref="word" class="word" :style="lightStyle" @mousedown.prevent="dragStart">light</span>
        into 
        <span ref="darkness">darkness</span>
      </span>
    </h4>
    <GameHelp v-if="help">
      Bring the “Light” over the “Darkness”<br>
      Or activate the Light Mode
    </GameHelp>
  </div>
</template>

<script>
import GameHelp from './Help'
import { EventBus } from '../../events'

export default {
  components: {
    GameHelp
  },
  name: 'LightDark',
  props: {
    help: {default: false},
  },
  data() {
    return {
      won: false,
      boxWidth: 0,
      boxHeight: 0,
      boxPaddingLeft: 0,
      boxPaddingRight: 0,
      top: 0,
      left: 0,
      StartY: 0,
      StartX: 0,
      dragStartY: 0,
      dragStartX: 0,
      fontSize: 0,
      moved: false,
    }
  },
  computed: {
    lightStyle() {
      return {
        top: `${this.top}px`,
        left: `${this.left}px`,
      }
    }
  },
  methods: {
    start() {
      EventBus.$emit('toggleDarkMode');
      EventBus.$on('toggleDarkMode', this.win);
    },
    win() {
      this.won = true;
      this.$emit('win');
      EventBus.$off('toggleDarkMode', this.win);
      this.$gtag.event('win_light_dark', {
        'event_category': 'game',
      })
    },
    dragStart(event) {
      if( !this.won ) {
        this.dragStartY = event.clientY;
        this.dragStartX = event.clientX;
        this.StartY = this.top;
        this.StartX = this.left;
        document.addEventListener('mousemove', this.dragMove);
        document.addEventListener('mouseup', this.dragOver);
      }
    },
    dragMove(event) {
      this.top = this.StartY + (event.clientY - this.dragStartY);
      this.left = this.StartX + (event.clientX - this.dragStartX);
    },
    dragOver() {
      document.removeEventListener('mousemove', this.dragMove);
      document.removeEventListener('mouseup', this.dragOver);
      let wordBox = this.$refs.word.getBoundingClientRect();
      let darkBox = this.$refs.darkness.getBoundingClientRect();
      let limit = {
        xMin: darkBox.x,
        xMax: darkBox.x + darkBox.width,
        yMin: darkBox.y,
        yMax: darkBox.y + darkBox.height,
      }
      let wordPoints = [
        [wordBox.x, wordBox.y],
        [wordBox.x, wordBox.y + wordBox.height],
        [wordBox.x + wordBox.width, wordBox.y],
        [wordBox.x + wordBox.width, wordBox.y + wordBox.height],
      ];
      for(let i = 0; i < wordPoints.length; i++ ) {
        let point = wordPoints[i];
        let insideX = point[0] >= limit.xMin && point[0] <= limit.xMax;
        let insideY = point[1] >= limit.yMin && point[1] <= limit.yMax;
        if( insideX && insideY ) {
          EventBus.$emit('toggleDarkMode');
          break;
        }
      }
    },
  },
  watch: {
  },
  mounted() {
    this.fontSize = parseInt(
      window.getComputedStyle(this.$refs.word).getPropertyValue('font-size')
      .replace('px', ''));
  }
}
</script>