<template>
    <nav class="menu">
        <router-link :to="{path: '/'}" class="menu_icon logo">
            <svg class="logo_circle">
                <use :href="`${icons}#circle`"></use>
            </svg>
            <svg class="logo_b">
                <use :href="`${icon}#b`"></use>
            </svg>
        </router-link>
        <button @click.prevent="toggleMenu">
            <svg>
                <use :href="`${icons}#menu`"></use>
            </svg>
        </button>
        <ul :class="{active: menuMode}">
            <li v-for="item in items" :key="item.title">
                <router-link :to="{name: item.name, path: item.path}" class="hover-bg" v-text="item.title">
                </router-link>
            </li>
        </ul>
        <a href="https://dev.blatstudio.com" class="menu_external">
            dev.BLAT
            <svg>
                <use :href="`${icons}#arrow-right`"></use>
            </svg>
        </a>
    </nav>
</template>

<script>
import icon from '../assets/B.svg'
import icons from '../assets/icons.svg'
export default {
  name: 'Menu',
  data() {
     return {
        icon, icons,
        items: [
            {title: 'Clients', name: 'clients' },
            {title: 'Work', name: 'work'},
            {title: 'Labs', name: 'labs'},
            {title: 'About', name: 'about'},
            {title: 'Contact', name: 'contact'},
        ],
        menuMode: false,
     } 
  },
  methods: {
    toggleMenu() {
      this.menuMode = !this.menuMode;
    },
  },
  watch: {
      $route() {
          this.menuMode = false;
      }
  }
}
</script>

<style>
</style>
