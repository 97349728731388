import './sass/style.scss'
import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from './router.js'
import Scroll from 'animated-scroll-to'
import Api from './api'
import store from './store'

// import './cookies'

import VueMeta from 'vue-meta'
Vue.use(VueMeta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true
})


Vue.config.productionTip = false

import VueGtag from "vue-gtag"
Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GTAG_ID },
}, router);

Vue.prototype.$scroll = Scroll
Vue.prototype.$api = Api
Vue.use(VueRouter)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
