<template>
  <div class="game weare" :class="{win: won}">
    <h4 :class="{'hover-bg': won}">
      <span><span v-text="message"></span><span class="blinking" v-if="!won">_</span></span>
    </h4>
    <GameHelp v-if="help">
      BLAT
    </GameHelp>
  </div>
</template>

<script>
import GameHelp from './Help'

export default {
  components: {
    GameHelp
  },
  name: 'WeAre',
  props: {
    help: {default: false},
  },
  data() {
    return {
      initMessage: "We are",
      won: false,
      input: '',
      length: 0,
    }
  },
  computed: {
    message() {
      if( this.won ) {
        if( this.length > 0) {
          return `${this.initMessage} ${this.input}`.slice(0, this.length);
        }
      }
      return `${this.initMessage} ${this.input}`;
    },
  },
  methods: {
    addInput(event) {
      if( event.key == 'Backspace' ) {
        this.removeInput();
      } else if(event.key == 'Enter' && !this.won ) {
        this.$gtag.event('fail_we_are', {
          'event_category': 'game',
          'event_label': this.input,
        })
        this.input = ''
      } else if(event.key.length == 1) {
        this.input = this.input.concat(event.key);
      }
    },
    removeInput() {
      this.input = this.input.slice(0, this.input.length - 1);
    },
    start() {
      document.addEventListener('keydown', this.addInput);
    },
    win() {
      this.length = this.message.length;
      this.won = true;
      this.$emit('win');
      document.removeEventListener('keydown', this.addInput);
      this.$gtag.event('win_we_are', {
        'event_category': 'game',
        'event_label': this.input,
      })
    }
  },
  watch: {
    input() {
      let words = [
        'you',
        'me',
        'blat',
        'good',
        'great',
        'friends',
        'friendly',
        'excelent',
        'amazing',
        'awesome',
        'creative',
        '1337',
        'studio',
      ];
      if( words.includes(this.input) ) this.win();
    }
  },
  mounted() {
  }
}
</script>