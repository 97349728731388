// import axios from 'axios'
const axios = require('axios');
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
// axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.interceptors.request.use(function (config) {
  config.headers = {
    ...config.headers,
    'Content-Type': 'application/json',
    // 'Accept': 'application/json',
  }
  if( config.url === undefined ) {
    console.log(config);
    throw "ERROR";
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});
export default axios