import Vue from 'vue'
import Vuex from 'vuex'

import people from './modules/people'
import posts from './modules/posts'
import projects from './modules/projects'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    people,
    posts,
    projects,
  },
  state: {},
  mutations: {}
})

export default store