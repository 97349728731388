<template>
  <div id="work" class="section">
    <h1 class="title title-section caps lean" v-text="title" v-if="$route.name == 'work'"></h1>
    <h2 class="title title-section caps lean" v-text="title" v-else></h2>
    <div class="selected-work">
      <WorkButton :ref="`btn-${work.id}`" :work="work" v-for="work in projects.slice(0, limit)" :key="work.id">
      </WorkButton>
    </div>
  </div>
</template>

<script>
import icon from '../../assets/plus.png';
import WorkButton from '../WorkButton';

export default {
  name: 'Work',
  metaInfo() {
    if(this.$route.name == 'work') {
      return {
        title: "Work"
      }
    }
    return {}
  },
  components: {
    WorkButton,
  },
  props: {
    title: {default: 'Our Work'},
    limit: {default: 6},
  },
  data() {
    return {
      icon,
    }
  },
  methods: {
    excerpt(work) {
      let el = document.createElement('div');
      el.innerHTML = work.excerpt.rendered;
      let maxLength = 240;
      let text = "";
      el.children.forEach(element => {
        let innerText = element.innerHTML.replace('<br>', ' /');
        if( text.length > 0 ) {
          text += ' / ';
        }
        text += innerText;
      });
      if( text.length > maxLength ) {
        text = `${text.substring(0, maxLength)}...`;
      }
      return text;
    },
    resetButtons() {
      Object.keys(this.$refs).forEach( (ref) => {
        if( ref.startsWith('btn-') ) {
          let btn = this.$refs[ref][0];
          btn.setStyle();
        }
      })
    },
  },
  computed: {
    projects() {
      return this.$store.getters['projects/work'];
    }
  },
  mounted() {
    if( this.projects.length == 0 ) {
      this.$store.dispatch('projects/getWork');
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/sass/_settings.scss';
@import '@/sass/_queries.scss';

.selected-work {
  width: calc(100% + #{$global-padding});
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 (-$global-padding/2);
  .workButton {
    flex-grow: 1;
    flex-basis: 100vw;
    margin: 0 ($global-padding/2) $global-padding ($global-padding/2);
    @include media(mobile) {
      &:not(:first-child) {
        margin-top: $global-padding/2;
      }
      &:not(:last-child) {
        margin-bottom: $global-padding/2;
      }
    }
    @include media(tablet-only) {
      flex-basis: calc(#{100% / 2} - #{$global-padding});
      width: calc(#{100% / 2} - #{$global-padding});
      max-width: calc(#{100% / 2} - #{$global-padding});
      &:not(:nth-child(-n+2)) {
        margin-top: $global-padding;
      }
    }
    @include media(desktop) {
      width: calc(#{100% / 3} - #{$global-padding});
      max-width: calc(#{100% / 3} - #{$global-padding});
    }
  }
}
</style>