import posts from '../../api/posts'

// initial state
const state = {
  posts: [],
  categories: [],
  tags: [],
}

// getters
const getters = {
  all: (state) => {
    return state.posts;
  },
  slug: (state) => (slug) => {
    let index = state.posts.findIndex( (post) => post.slug == slug )
    if( index >= 0 ) {
      return state.posts[index]
    }
    return null
  }
  // category: (state) => (cat, ignoreTag) => {
  //   let projects = [];
  //   for (let i = 0; i < state.projects.length; i++) {
  //     const project = state.projects[i];
  //     if( project.project_category.includes(cat.cat_ID) && !project.project_tag.includes(ignoreTag.cat_ID) ) {
  //       projects.push(project);
  //     }
  //   }
  //   return projects;
  // },
  // categogy_tag: (state) => (cat, tag) => {
  //   let projects = [];
  //   for (let i = 0; i < state.projects.length; i++) {
  //     const project = state.projects[i];
  //     if( project.project_category.includes(cat.cat_ID) && project.project_tag.includes(tag.cat_ID) ) {
  //       projects.push(project);
  //     }
  //   }
  //   return projects;
  // },
  // category_slug: (state) => (category, tag) => {
  //   let projects = [];
  //   for (let i = 0; i < state.projects.length; i++) {
  //     const project = state.projects[i];
  //     if( project.project_category.includes(category.cat_ID) && project.project_tag.includes(tag.cat_ID) ) {
  //       projects.push(project);
  //     }
  //   }
  //   return projects;
  // },
  // without_tag: (state) => (tag) => {
  //   let projects = [];
  //   for (let i = 0; i < state.projects.length; i++) {
  //     const project = state.projects[i];
  //     if( !project.project_tag.includes(tag.cat_ID) ) {
  //       projects.push(project);
  //     }
  //   }
  //   return projects;
  // },
  // tag: (state) => (tag) => {
  //   let projects = [];
  //   for (let i = 0; i < state.projects.length; i++) {
  //     const project = state.projects[i];
  //     if( project.project_tag.includes(tag.cat_ID) ) {
  //       projects.push(project);
  //     }
  //   }
  //   return projects;
  // },
  // tagsSlugs: (state) => (slugs) => {
  //   return state.tags.filter((tag) => slugs.includes(tag.slug) );
  // },
  // slug: (state) => (slug) => {
  //   return state.projects.find( (project) => project.slug === slug );
  // },
}

// actions
const actions = {
  getAll({ commit }) {
    posts.get( receivedPosts => {
      commit('add', receivedPosts);
    })
  },
  // getTags({commit}) {
  //   projects.getTag((tags) => {
  //     commit('addTags', tags);
  //   });
  // },
  // getSlug({commit}, slug) {
  //   projects.get( receivedProjects => {
  //     commit('add', receivedProjects);
  //   }, {slug: slug})
  // },
}

// mutations
const mutations = {
  add(state, posts) {
    let all = [...state.posts];
    posts.forEach( (post) => {
      let index = all.findIndex( ({id}) => id == post.id);
      if( index > -1 ) {
        all.splice( index, 1 );
      }
      all.push( post );
    })
    state.posts = all;
  },
  // addCategories(state, categories) {
  //   let newCategories = [...state.categories];
  //   categories.forEach( (category) => {
  //     let index = categories.findIndex( ({term_id}) => term_id == category.term_id);
  //     if( index > -1 ) {
  //       newCategories.splice( index, 1 );
  //     }
  //     newCategories.push( category );
  //   })
  //   state.categories = newCategories;
  // },
  // addTags(state, tags) {
  //   let newTags = [...state.tags];
  //   tags.forEach( (tag) => {
  //     let index = tags.findIndex( ({term_id}) => term_id == tag.term_id);
  //     if( index > -1 ) {
  //       newTags.splice( index, 1 );
  //     }
  //     newTags.push( tag );
  //   })
  //   state.tags = newTags;
  // },
  // setProjects(state, projects) {
  //   state.all = projects
  // },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
