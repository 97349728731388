<template>
  <div id="clients" class="section">
    <h2 class="title title-section caps lean" v-text="title"></h2>
    <div class="clients">
      <a :href="work.url" class="work" rel="noreferrer noopener"  v-for="work in clients" :key="work.id">
        <img :src="work.featured_image.original" :alt="work.title.rendered" class="featured-image">
      </a>
      <div class="clients_more" v-if="tags">
        <router-link :to="{name: 'clients'}" class="clients_mode">
          <span>Ver mais</span>
          <svg>
            <use href="../../assets/icons.svg#plus"></use>
          </svg>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Clients',
  metaInfo() {
    if(this.$route.name == 'clients') {
      return {
        title: "Clients"
      }
    }
    return {}
  },
  props: {
    tags: {default: null},
    title: {default: "Our Brands"},
  },
  data() {
    return {
    }
  },
  computed: {
    tagsArray() {
      if( this.tags ) {
        return this.$store.getters['projects/tagsSlugs'](this.tags);
      }
      return null;
    },
    clients() {
      if( this.tagsArray != null ) {
        return this.$store.getters['projects/clients'](this.tagsArray);
      }
      return this.$store.getters['projects/clients']();
    },
  },
  methods: {
    fetchTags() {
      // if( this.tagsArray == null || this.tagsArray.length == 0 ) {
        this.$store.dispatch('projects/getTags');
      // }
    },
    fetchClients() {
      if( this.clients.length == 0 ) {
        this.$store.dispatch('projects/getClients');
      }
    },
  },
  watch: {
  },
  mounted() {
    this.fetchTags();
    this.fetchClients();
  }
}
</script>