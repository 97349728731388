<template>
  <div class="home">
    <GameIntro @start="startGame" @scrollDown="visitHome" @skip="skipGame" ref="intro"></GameIntro>
    <template v-if="playing">
      <BlatGame v-for="(game, index) in games"
        :ref="game" :key="game" :game="game" :help="help"
        @win="won(game)"
        :class="{active: level == index+1}"/>
      <transition name="fade">
        <div class="home_progress" v-if="showProgress">
          <div v-for="(game, index) in games" :key="game" :class="{current: level == index+1}"></div>
        </div>
      </transition>
    </template>
    <HelpButton v-if="playing"></HelpButton>
    <QuitButton v-if="playing"></QuitButton>
    <BlatWork v-show="showHome" ref="work" title="Selected Work" help="help" limit="3"></BlatWork>
    <BlatLabs v-show="showHome" ref="labs" :limit="4"></BlatLabs>
    <BlatClients v-show="showHome" :tags="['selected']" title="Some of our Brands" ref="clients"></BlatClients>
    <div v-show="showHome" ref="contacts" id="contact" class="section">
      <h2 class="title title-section caps lean">Got a challenge for us</h2>
      <h4>
        <router-link :to="{name: 'contact'}" class="hover-bg">Get in touch</router-link>
      </h4>
    </div>
  </div>
</template>

<script>
import HelpButton from '../HelpButton.vue'
import QuitButton from '../QuitButton.vue'
import BlatClients from '../templates/Clients'
import BlatGame from '../games/Index'
import BlatLabs from '../templates/Labs'
import BlatWork from '../templates/Work'
import Cookies from 'js-cookie'
import GameIntro from '../games/Intro'

import { EventBus } from '../../events'

export default {
  name: 'Home',
  metaInfo: {
    title: "BLAT - Design, Web, Apps, Social media marketing, Creativity",
    titleTemplate: "",
    meta: [
      { name: 'description', content: 'Blat is a new and fresh blood and creative digital agency based in lisbon, Portugal (for now)'},
      { property: 'og:title', content: 'BLAT'},
      { property: 'og:description', content: 'Blat is a new and fresh blood and creative digital agency based in lisbon, Portugal (for now)'},
    ]
  },
  props: {
  },
  components: {
    BlatClients,
    BlatGame,
    BlatLabs,
    BlatWork,
    GameIntro,
    HelpButton,
    QuitButton,
  },
  data() {
    return {
      played: false,
      showProgress: false,
      showHome: false,
      playing: false,
      help: false,
      level: 0,
      scrollTimeout: null,
      games: [
        'box',
        'lightDark',
        'onTime',
        'weAre',
      ]
    }
  },
  computed: {
    activeGame() {
      if( this.level == 0 ) {
        return this.$refs.intro;
      } else if( this.level-1 < this.games.length) {
        return this.$refs[this.games[ this.level - 1 ]][0];
      }
      return null;
    },
  },
  watch: {
    activeGame: {
      handler() {
        if( this.activeGame ) {
          this.activeGame.start();
          setTimeout( () => this.$scroll(this.activeGame.$el), 200);
        }
      },
      immediate: true,
    },
    playing() {
      EventBus.$emit('gameMode', this.playing);
      if( this.playing ) {
        document.addEventListener('scroll', this.scrollListener);
      } else {
        document.removeEventListener('scroll', this.scrollListener);
      }
      this.endGame(this.playing)
      EventBus.$emit('playing', this.playing);
    },
    showHome() {
      if( this.showHome ) {
        this.$nextTick().then( () => {
          this.$refs.work.resetButtons();
          this.$refs.labs.resetButtons();
        })
      }
    }
  },
  methods: {
    getHelp() {
      this.help = !this.help
    },
    scrollListener() {
      clearTimeout(this.scrollTimeout);
      this.scrollTimeout = setTimeout(() => {
        if( this.activeGame ) {
          this.$scroll(this.activeGame.$el)
        }
      }, 200);
    },
    visitHome() {
      this.$scroll(this.$refs.work.$el);
    },
    won(level) {
      switch (level) {
        case this.games[this.games.length - 1]:
          Cookies.set('played', 1, { expires: 365 })
          this.endGame()
          break;
        default:
          break;
      }
      this.level++;
    },
    skipGame() {
      if( !this.played ) {
        this.$gtag.event('skip_game', {
          'event_category': 'game',
        })
      }
      this.playing = false;
      this.showHome = true;
    },
    startGame() {
      this.playing = true;
      this.$nextTick().then( () => {
        this.won();
        this.showProgress = true;
      });
    },
    endGame(keepPlaying = false) {
      if( this.help && !keepPlaying) {
        this.getHelp()
        return
      }
      if( !keepPlaying ) {
        this.showProgress = false;
        if( this.games.length  == this.level ) {
          this.$gtag.event('finished_game', {
            'event_category': 'game',
          })
        }
        setTimeout( () => {
          // this.$scroll(this.$refs.intro.$el)
          this.$scroll(0)
          .then(() => {
            this.$refs.intro.finished();
            this.showHome = true;
          });
        }, 200);
      }
    },
  },
  mounted() {
    this.played = parseInt(Cookies.get('played'));
    if( this.played ) {
      this.endGame()
    }
    let style = getComputedStyle(this.$el)
    let desktop = parseInt(style.getPropertyValue("--desktop"))
    if (!desktop) {
      this.endGame()
    }
    EventBus.$on('gameMode', this.endGame)
    EventBus.$on('getHelp', this.getHelp)
  },
  destroyed() {
    EventBus.$off('gameMode', this.endGame)
    EventBus.$on('getHelp', this.getHelp)
    document.removeEventListener('scroll', this.scrollListener)
  }
}
</script>

<style scoped>
</style>
