import api from './index.js'

export default {
    get(cb, params = {}) {
        params = {
            page: 1,
            per_page: 100,
            ...params,
        }
        api.get('wp/v2/media', params)
        .then( (res) => {
            cb(res.data);
            let totalPages = res.headers['x-wp-totalpages'];
            let pages = totalPages == null ? 1 : parseInt(totalPages);
            if( pages > params.page ) {
                this.get(cb, {...params, page: params.page + 1});
            }
        });
    },
}