<template>
  <GameBox v-if="game == 'box'" @win="won()" :help="help"></GameBox>
  <GameLightDark v-else-if="game == 'lightDark'" @win="won()" :help="help"></GameLightDark>
  <GameOnTime v-else-if="game == 'onTime'" @win="won()" :help="help"></GameOnTime>
  <GameWeAre v-else @win="won()" :help="help"></GameWeAre>
</template>

<script>
import GameBox from '../games/Box';
import GameLightDark from '../games/LightDark';
import GameOnTime from '../games/OnTime';
import GameWeAre from '../games/WeAre';

export default {
  name: 'Game',
  components: {
    GameBox,
    GameLightDark,
    GameOnTime,
    GameWeAre,
  },
  props: {
    game: String,
    help: {default: false},
  },
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
    start() {
      this.$children[0].start();
    },
    won() {
      this.$emit('win');
    }
  },
  watch: {
  },
  mounted() {
  }
}
</script>