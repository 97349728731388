import api from './index.js'

export default {
    get(cb, params = {}) {
        params = {
            page: 1,
            per_page: 100,
            ...params,
        }
        api.get('wp/v2/posts', params)
        .then( (res) => {
            cb(res.data);
            let totalPages = res.headers['x-wp-totalpages'];
            let pages = totalPages == null ? 1 : parseInt(totalPages);
            if( pages > params.page ) {
                this.get(cb, {...params, page: params.page + 1});
            }
        });
    },
    // getCategory(cb, params = {page: 1, per_page: 100}, all = true) {
    //     api.get('blat/v1/project_categories').then( (res) => {
    //         cb(res.data);
    //         if( all ) {
    //             let totalPages = res.headers['x-wp-totalpages'];
    //             let pages = totalPages == null ? 1 : parseInt(totalPages);
    //             if( pages > params.page ) {
    //                 this.getCategory(cb, {...params, page: params.page + 1}, all, res.data);
    //             }
    //         }
    //     })
    // },
    // getTag(cb, params = {page: 1, per_page: 100}, all = true) {
    //     api.get('blat/v1/project_tags').then( (res) => {
    //         cb(res.data);
    //         if( all ) {
    //             let totalPages = res.headers['x-wp-totalpages'];
    //             let pages = totalPages == null ? 1 : parseInt(totalPages);
    //             if( pages > params.page ) {
    //                 this.getTag(cb, {...params, page: params.page + 1}, all, res.data);
    //             }
    //         }
    //     })
    // },
}