<template>
  <div class="game ontime" :class="{win: won}">
    <h4>
      <span>We are always on time</span>
    </h4>
    <p class="input">
      <span ref="hour" class="hour" :class="{selected: selected == 'hour'}" @click.prevent="selected = 'hour'">
        <span v-text="hour[0]"></span>
        <span v-text="hour[1]" v-if="hour.length > 1"></span>
        <span class="blinking" v-if="hour.length < 2 && selected == 'hour'">_</span>
      </span>:<span ref="minutes" :class="{selected: selected == 'minutes'}" class="minutes">
        <span v-text="minutes[0]" v-if="minutes.length"></span>
        <span v-text="minutes[1]" v-if="minutes.length > 1"></span>
        <span class="blinking" v-if="minutes.length < 2 && selected == 'minutes'">_</span>
      </span>
    </p>
    <GameHelp v-if="help">
      What time is it?
    </GameHelp>
  </div>
</template>

<script>
import GameHelp from './Help'

export default {
  components: {
    GameHelp
  },
  name: 'OnTime',
  props: {
    help: {default: false},
  },
  data() {
    return {
      won: false,
      hour: '',
      minutes: '',
      selected: null,
    }
  },
  computed: {
  },
  methods: {
    addInput(event) {
      let input = parseInt(event.key);
      if( !isNaN(input) ) {
        if( this.selected == 'hour' ) {
          this.hour = this.hour.concat(input);
          if( this.hour.length >= 2 ) {
            this.selected = 'minutes';
          }
        } else if( this.selected == 'minutes' ) {
          this.minutes = this.minutes.concat(input);
          if( this.minutes.length >= 2 ) {
            this.selected = null;
            let date = new Date(Date.now());
            if( date.getHours() == parseInt(this.hour) && date.getMinutes() == parseInt(this.minutes) ) {
              this.win();
            } else {
              this.$gtag.event('fail_on_time', {
                'event_category': 'game',
              })
              setTimeout(() => {
                this.selected = 'hour';
                this.hour = this.minutes = '';
              }, 500);
            }
          }
        }
      } else if( event.key == 'Backspace' ) {
        this.removeInput();
      }
    },
    removeInput() {
      if( this.minutes.length ) {
        this.minutes = this.minutes.slice(0, this.minutes.length - 1 );
        if( this.minutes.length ) {
          this.selected = 'minutes';
        } else {
          this.selected = 'hour';
        }
      } else {
        this.hour = this.hour.slice(0, this.hour.length - 1 );
      }
    },
    start() {
      this.selected = 'hour';
      document.addEventListener('keydown', this.addInput);
    },
    win() {
      this.won = true;
      this.$emit('win');
      document.removeEventListener('keydown', this.addInput);
      this.$gtag.event('win_on_time', {
        'event_category': 'game',
      })
    }
  },
  watch: {
  },
  mounted() {
  }
}
</script>