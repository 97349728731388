<template>
  <div class="game box" :class="{win: win}">
    <h4>
      <span>We
        <span class="box" ref="box">
          <span class="inside" ref="word" :style="thinkStyle" @mousedown.prevent="dragStart">think</span>
        </span>
        outside the box
      </span>
    </h4>
    <GameHelp v-if="help">
      Try to bring/move the Think anywhere outside of the box, like we in every project 
    </GameHelp>
  </div>
</template>

<script>
import GameHelp from './Help'

export default {
  components: {
    GameHelp
  },
  name: 'GameBox',
  props: {
    msg: String,
    help: {default: false},
  },
  data() {
    return {
      boxWidth: 0,
      boxHeight: 0,
      boxPaddingLeft: 0,
      boxPaddingRight: 0,
      top: 0,
      left: 0,
      StartY: 0,
      StartX: 0,
      dragStartY: 0,
      dragStartX: 0,
      fontSize: 0,
      moved: false,
      win: false,
    }
  },
  computed: {
    thinkStyle() {
      return {
        top: `${this.top}px`,
        left: `${this.left}px`,
      };
    },
  },
  methods: {
    start() {},
    dragStart(event) {
      if( !this.win ) {
        this.dragStartY = event.clientY;
        this.dragStartX = event.clientX;
        this.StartY = this.top;
        this.StartX = this.left;
        document.addEventListener('mousemove', this.dragMove);
        document.addEventListener('mouseup', this.dragOver);
      }
    },
    dragMove(event) {
      this.top = this.StartY + (event.clientY - this.dragStartY);
      this.left = this.StartX + (event.clientX - this.dragStartX);
    },
    dragOver() {
      document.removeEventListener('mousemove', this.dragMove);
      document.removeEventListener('mouseup', this.dragOver);
      let fontPadding = (this.$refs.word.offsetHeight - this.fontSize)/2;
      let overTop = (this.top + (this.fontSize + fontPadding) - 5) < 0;
      let overBottom = (((fontPadding + this.$refs.word.offsetHeight) - this.top) - 15) < 0;
      let overLeft = (this.left + this.$refs.word.offsetWidth + this.boxPaddingLeft - 5) < 0;
      let overRight = ((this.$refs.word.offsetWidth + this.boxPaddingLeft) - this.left) < 0;
      if( overTop || overBottom || overLeft || overRight ) {
        this.win = true;
      } else {
        this.top = 0;
        this.left = 0;
      }
    },
  },
  watch: {
    win() {
      if( this.win ) {
        this.$emit('win');
        this.$gtag.event('win_box', {
          'event_category': 'game',
        })
      }
    }
  },
  mounted() {
    let boxStyle = window.getComputedStyle(this.$refs.box);
    this.boxPaddingLeft = parseInt(boxStyle.getPropertyValue('padding-left'));
    this.boxPaddingRight = parseInt(boxStyle.getPropertyValue('padding-right'));
    this.boxWidth = this.$refs.box.offsetWidth;
    this.boxHeight = this.$refs.box.offsetHeight;
    this.fontSize = parseInt(
      window.getComputedStyle(this.$refs.word).getPropertyValue('font-size')
      .replace('px', ''));
  }
}
</script>